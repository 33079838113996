import * as React from "react"
import { Container, Carousel } from "react-bootstrap" 
import { StaticImage } from "gatsby-plugin-image"

const carouselImg = "../../../images/slider.png";
const iconImg = "../../../images/destinations.png";


function Hero() {
	return (

        
        <div class="main-slideshow">
            
<div class="elfsight-app-7e2e2f43-3c1d-451c-bb0c-95855e39d414"></div>
            <div className="mobile-hero">
              <div class="elfsight-app-a3074f1d-c191-45d2-9106-078e5c0c21ae"></div>
            </div>
            <div className="desktop-main">
         <div id="video-bg">  
        <iframe frameborder="0" 
            src="https://youtube.com/embed/eARWW5R5ftE?version=3&loop=1&playlist=eARWW5R5ftE&start=13&autoplay=1&mute=1&controls=0&showinfo=0" allowfullscreen>
        </iframe>
        </div> 
            <div class="main-overlay"></div>
            <div class="hero-text">

                <div id="myCarousel" class="carousel slide" data-ride="carousel">
                   
                
                    
                    <div class="carousel-inner">
                        <div class="item active">
                        <StaticImage src={carouselImg} quality={60} formats={["auto", "webp", "avif"]} />
                            <div class="carousel-caption">
                                
                                <h3>Make Your</h3>
                                <h1>Tours Amazing</h1>
                                <p>Cave Tubing away from the crowds! Located at St. Herman's Blue Hole National Park. Tours are relaxing and private
                                    because we don't cater to cruise ship customers.
                                </p>
                                <a href="/cave-tubing-in-belize-with-belize-inland-tours" title="Cave Tubing in Belize"><button id="primary-button" type="button">Book Now</button></a>
                            </div>
                        </div>                 
                
                      <div class="item">
                        <StaticImage src={carouselImg} quality={60} formats={["auto", "webp", "avif"]} />
                            <div class="carousel-caption">
                                <h3>Never Stop</h3>
                                <h1>Exploring</h1>
                                <p>Our Jungle Survival Tours teaches you how to survive on roots, leaves, nuts, and whatever mother nature has to offer.
                                Our Guides will also take their time showing & describing each bird as you walk along a designated 
                                path in the Jungle.
                                </p>
                                <a href="/tours" title="Cave Tubing in Belize"><button id="primary-button" type="button">View Tours</button></a>
                            </div>
                          </div>




                
                        <div class="item">
                        <StaticImage src={carouselImg} quality={60} formats={["auto", "webp", "avif"]} />
                            <div class="carousel-caption">
                                <h3>Travel More, Create</h3>
                                <h1>More Memories</h1>
                                <p>Discover our Ancient Mayan Ruins with our guides which are very informative 
                                    and entertaining!
                                </p>
                                <a href="#contact-us"><button id="primary-button" type="button">Get a Quote</button></a>
                            </div>
                        </div>
                    </div>
                
                  
                    <a class="left carousel-control" href="#myCarousel" data-slide="prev">
                        <span class="glyphicon glyphicon-chevron-left"></span>
                        <span class="sr-only">Previous</span>
                    </a>
                    <a class="right carousel-control" href="#myCarousel" data-slide="next">
                        <span class="glyphicon glyphicon-chevron-right"></span>
                        <span class="sr-only">Next</span>
                    </a>
                </div>
            </div>
            </div>

            </div>

    );
}

export default Hero;